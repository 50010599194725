<template>
  <div class="useTemplateList">
    <BaseDialog
      title="保存模板"
      :isshow="visible"
      @handleShow="
        (val) => {
          visible = val
        }
      "
      width="600px"
      top="10%"
    >
      <div class="consumables-search">
        <el-form
          :model="ruleForm"
          ref="ruleFormRef"
          label-width="100px"
          label-position="right"
          :rules="Rules.ADDTemp"
          class="demo-ruleForm col-333"
        >
          <el-form-item label="当前周次：">
            <el-input
              v-model="ruleForm.week"
              disabled
              placeholder="自动获取当前周次——无法修改"
            ></el-input>
          </el-form-item>
          <el-form-item label="模板名称：" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入模板名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="内容描述：">
            <el-input
              v-model="ruleForm.desc"
              type="textarea"
              :rows="4"
              placeholder="请输入备注"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <div class="dialog-footer">
          <el-button
            class="color-cancel"
            type="primary"
            @click="visible = false"
          >
            取消
          </el-button>
          <el-button
            @click="savePatient"
            :loading="saveLoad"
            class="color-determine"
            type="primary"
          >
            确定
          </el-button>
        </div>
      </template>
    </BaseDialog>
  </div>
</template>

<script setup>
import { reactive, ref, defineExpose, nextTick } from 'vue'
import service from '@/utils/request'
import BaseDialog from '@/components/Dialog/index.vue'
import { ElMessage } from 'element-plus'
import Rule from '@/utils/rule'

const ruleForm = ref({})
const visible = ref(false)
const ruleFormRef = ref(null)
const Rules = reactive(Rule)

const initData = (item) => {
  visible.value = true
  ruleForm.value.week = item
  ruleForm.value.name = `第${item}周`
  ruleForm.value.desc = ''
  nextTick(() => {
    ruleFormRef.value.clearValidate()
  })
}

// 保存
const saveLoad = ref(false)
const savePatient = () => {
  ruleFormRef.value.validate(async (valid) => {
    if (valid) {
      saveLoad.value = true
      let res = await service.post('/api/schedul_tml/create', ruleForm.value)
      saveLoad.value = false
      if (res.code === 0) {
        ElMessage.success(res.msg)
        visible.value = false
      }
    }
  })
}

defineExpose({
  initData,
})
</script>

<style scoped lang="scss">
.consumables-search {
  margin-top: 16px;
}
.w-240 .el-input {
  width: 240px;
}
</style>
